import React from "react";
import { Store as store } from 'react-notifications-component';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import { Component } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import Home from "./Home";

import Profile from "./Profile";
import Nav from "./Nav";
import Auth from "./Auth/Auth";
import Options from "./Options";
import Callback from "./Callback";
import Privacy from "./Privacy";
import HowTo from "./HowTo";
import NewSubscriptionPage from "./NewSubscriptionPage";
import NewsEarnings from "./NewsEarnings";
import ThankYou from "./ThankYou";
// import nameAndSlogan from "./images/nameAndSlogan.png";
import Legal from "./Legal";
import TermsOfService from "./TermsOfService";
import Fish from "./Fish";
import stocktwits from "./images/stocktwitsTransparent2.png";
import discord from "./images/discord_White.png";
import { axiosClient } from "./BASE_URL";
const tagManagerArgs = {
  gtmId: "GTM-P5TKQ2LP",
};

TagManager.initialize(tagManagerArgs);
const HEALTH_CHECK_TIMEOUT = 300000;
const AUTH_TIMEOUT = 900000;
class App extends Component {
  intervalID;
  authenticationIntervalID;
  // promo_notification = {
  //   title: "Black Friday",
  //   message: "Join now and use  code TG50 for 25% off the monthly plan. Promotion available until Monday November 29th, 2021.",
  //   type: "success",
  //   insert: "top",
  //   container: "top-center",
  //   animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
  //   animationOut: ["animate__animated animate__fadeOut"],
  //   width: 200,
  //   dismiss:{
  //     duration: 0,
  //     showIcon: true
  //   }
  // }
  notification = {
    title: "System Performance",
    message: "We are currently experiencing higher than expected demand. We apologize for the inconvenience and are working to resolve the issue.",
    type: "warning",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated animate__fadeOut"],
    width: 150,
    dismiss: {
      duration: 0,
      showIcon: true
    }
  };
  constructor(props) {
    super(props);
    this.auth = new Auth(this.props.navigate);
    this.refreshPage = this.refreshPage.bind(this);

  }
  state = {
    alertId: null,
    isHealthy: true
  }


  componentDidMount() {
    // store.addNotification(this.promo_notification);
    this.updateNotification();
    this.authenticateOrRedirect();
  }
  refreshPage() {
    window.location.reload(false);
  }
  async getServerHealth() {
    let healthResponse = (await axiosClient.get("/health")).data;
    this.setState({ isHealthy: healthResponse['overall_health'] });
  }
  authenticateOrRedirect() {
    if (this.auth.hasUserLoggedIn && !this.auth.isAuthenticated()) {
      this.auth.logout();
    }
    this.authenticationIntervalID = setTimeout(this.authenticateOrRedirect.bind(this), AUTH_TIMEOUT);
  }
  async updateNotification() {
    await this.getServerHealth();
    if (this.state.isHealthy && this.state.alertId) {
      store.removeNotification(this.state.alertId);
      try {
        this.setState({ alertId: null })
      }
      catch (e) {
        console.log(e);
      }

    }
    else if (!this.state.isHealthy && this.state.alertId == null) {
      let alert = store.addNotification(this.notification);
      this.setState({ alertId: alert })
    }
    this.intervalID = setTimeout(this.updateNotification.bind(this), HEALTH_CHECK_TIMEOUT);
  }

  render() {

    return (
      <div className="body">
        {/* <img
          src={nameAndSlogan}
          alt="Calls or Puts: Visualize the Answer"
          width="400px"
          className="center"
        ></img>
        */}
        <ReactNotifications />
        <Nav auth={this.auth} />
        <Routes>
          <Route
            path="/callback"
            element={<Callback auth={this.auth} location={window.location} />}
          />
          <Route
            index
            element={
              !this.auth.isAuthenticated() ? (
                <Home auth={this.auth} />
              ) : (
                <Navigate to="/options" replace={true} />
              )}
          />

          <Route
            path="/subscribe"
            render={(props) => (
              <NewSubscriptionPage auth={this.auth} {...props} />
            )}
          />
          <Route
            path="/ThankYou"
            element={
              <ThankYou
                auth={this.auth}
              />
            }
          />
          <Route
            path="/HowTo"
            exact
            element={<HowTo auth={this.auth} />}
          />
          <Route
            path="/News"
            element={<NewsEarnings auth={this.auth} />}
          />
          <Route
            path="/profile"
            element={
              this.auth.isAuthenticated() ? (
                <Profile
                  auth={this.auth}
                  refreshPage={this.refreshPage}
                />
              ) : (
                <Navigate to="/" replace={true} />
              )
            }
          />
          <Route
            path="/options"
            exact
            element={<Options auth={this.auth} />}
          />
          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/tos" exact element={<TermsOfService />} />
          <Route path="/legal" exact render={<Legal />} />
          <Route path="/chanfish" exact render={<Fish />} />
        </Routes>
        {/* <!-- The social media icon bar --> */}
        <div class="icon-bar">
          <a href=" https://www.instagram.com/callsorputs" className="instagram">
            <i class="fa fa-instagram"></i>
          </a>
          <a href="https://x.com/togiveachance" className="twitter">
            <i class="fa fa-twitter"></i>
          </a>
          <a href="https://www.facebook.com/iamchanlo" className="facebook">
            <i class="fa fa-facebook"></i>
          </a>
          <a href="https://discord.gg/g3H3Zu4" className="discord">
            <img src={discord} alt="Join Our Discord" width="14px"></img>
          </a>

          <a href="https://stocktwits.com/iamchanlo" className="stocktwits">
            <img src={stocktwits} alt="Join Stocktwits" width="14px"></img>
          </a>
        </div>

        <Legal />
      </div>
    );
  }
}

export default function AppWithNavigate() {
  const navigate = useNavigate();
  return <App navigate={navigate} />;
}

